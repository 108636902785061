<template>
  <b-card>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_rhqf5fyejg1i-0 start">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c7">Tambah Keluhan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span>Untuk pengguna eksternal atau selain karyawan KAN Jabung yang terdaftar di sistem, maka dapat mengakses url berikut: </span><span class="c11"><a
          class="c12"
          href="https://www.google.com/url?q=https://siap.kanjabung.my.id/keluhan&amp;sa=D&amp;source=editors&amp;ust=1653026132678587&amp;usg=AOvVaw1EatLw9u7g7jotMnDYGOJx"
        >https://siap.kanjabung.my.id/keluhan</a></span><span class="c2">, dan akan tampil seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 216.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image15.png')"
          style="width: 601.70px; height: 216.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span>Hasil keluhan yang telah </span><span>ditambakan</span><span class="c2">&nbsp;akan masuk ke daftar keluhan</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_eimdw9swczjx-0">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Edit Keluhan</span>
      </li>
    </ul>
    <p class="c3">
      <span class="c2">&nbsp;</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 96.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image11.png')"
          style="width: 601.70px; height: 96.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Merubah data keluhan hanya dapat dilakukan untuk Staff SE, dengan cara pilih tombol edit pada kolom aksi, selanjutnya akan mengarah pada detail keluhan yang dipilih sebagai berikut:</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 302.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image25.png')"
          style="width: 601.70px; height: 302.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Selain pengguna dengan status staff SE maka tidak bisa edit data keluhan, dengan tampilan seperti berikut</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 293.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image2.png')"
          style="width: 601.70px; height: 293.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_7weoqdu5kyvl-0">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Komentar Keluhan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c1" />
    </p>
    <p class="c3">
      <span class="c2">Akun yang dipilih menjadi PIC dan responder memiliki akses berkomentar pada data keluhan seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 152.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image20.png')"
          style="width: 601.70px; height: 152.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Setelah keluhan selesai maka admin atau staff SE dapat memilih untuk menutup sesi keluhan melalui opsi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_i2feexisqodn-0">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Tutup Keluhan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c1" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 173.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image13.png')"
          style="width: 601.70px; height: 173.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3">
      <span>Setelah itu pilih tombol simpan dan proses keluhan telah selesai</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>

  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 0;
  li {
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
